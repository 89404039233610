'use client';

import * as React from 'react';
import { addDays, format, subDays } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import {
  DateRange,
  DayPicker,
  DayPickerRangeProps,
  type DayPickerBase,
} from 'react-day-picker';

import { cn } from '../../utils';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { useNavigation } from '@remix-run/react';
import { useState } from 'react';

export type DatePickerWithRangeProps = DayPickerRangeProps;

export function DatePickerWithRange(
  props: {
    className: string;
    onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
    onLastWeek?: React.MouseEventHandler<HTMLButtonElement>;
  } & DatePickerWithRangeProps
) {
  const { className, selected } = props;
  const navigation = useNavigation();
  const disabled =
    navigation.state === 'submitting' || navigation.state === 'loading';
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  return (
    <div className={cn('grid gap-2')}>
      <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !selected && 'text-muted-foreground',
              className
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, 'LLL dd, y')} -{' '}
                  {format(selected.to, 'LLL dd, y')}
                </>
              ) : (
                format(selected.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            defaultMonth={selected?.from}
            toDate={new Date()}
            numberOfMonths={1}
            captionLayout="buttons"
            {...props}
          />
          <div className="flex flex-col items-center space-y-4 p-4">
            {selected?.from && (
              <button
                type="button"
                disabled={disabled}
                onClick={(e) => {
                  if (props.onSubmit) {
                    props.onSubmit(e);
                  }
                  setIsCalendarOpen(false);
                }}
                className="w-full h-10 justify-center text-center text-blue-600 disabled:bg-gray-100 disabled:opacity-50 disabled:cursor-wait bg-blue-50 rounded cursor-pointer flex items-center"
              >
                Apply
              </button>
            )}
            {/* <button
              type="button"
              onClick={props.onLastWeek}
              className="w-full h-10 justify-center text-center text-cyan-600 bg-cyan-50 cursor-pointer flex items-center"
            >
              Last Week
            </button> */}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
