import { cn } from '../../utils';
import {
  Link as RemixLink,
  type LinkProps as RemixLinkProps,
  NavLink as RemixNavLink,
  type NavLinkProps as RemixNavLinkProps,
} from '@remix-run/react';
import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { buttonVariants } from './button';

export interface NavLinkProps
  extends RemixNavLinkProps,
    VariantProps<typeof buttonVariants> {}

const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <RemixNavLink
        className={({ isActive }) =>
          cn(
            buttonVariants({
              variant,
              size,
              className,
              active: isActive ? 'active' : 'default',
            })
          )
        }
        ref={ref}
        {...props}
      />
    );
  }
);
NavLink.displayName = 'NavLink';

export interface LinkProps
  extends RemixLinkProps,
    VariantProps<typeof buttonVariants> {}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <RemixLink
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Link.displayName = 'Link';

export { NavLink, Link };
