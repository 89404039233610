import {
  PlusCircle,
  TrendingUp,
  TrendingDown,
  Newspaper,
  RadioTower,
  MinusCircle,
} from 'lucide-react';
import { Button } from './button';
import { Badge } from './badge';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from './card';

export interface BaseballCardProps {
  title: string;
  sentiment:
    | 'Positive'
    | 'Negative'
    | 'Neutral/Mixed'
    | 'Leaning Positive'
    | 'Leaning Negative';
  onFollow: (isFollowed: boolean, id: string) => void;
  isFollowed: boolean;
  stats: {
    sentiment: number;
    news: number;
    pulse: number;
  };
  sources: { label: string; value: number }[];
}

export const BaseballCard = ({
  title,
  sentiment,
  onFollow,
  isFollowed,
  stats,
  sources,
}: BaseballCardProps): JSX.Element => {
  return (
    <div className="w-full max-w-[300px] p-1 md:p-2">
      <Card className="border-0 shadow-sm">
        <CardHeader className="flex flex-row">
          <div className="w-2/3">
            <CardTitle className="text-sm font-semibold">{title}</CardTitle>
            <CardDescription
              className={`text-xs 
                    ${
                      ['Positive', 'Leaning Positive'].includes(sentiment ?? '')
                        ? 'text-green-500'
                        : ['Negative', 'Leaning Negative'].includes(
                            sentiment ?? ''
                          )
                        ? 'text-red-500'
                        : 'text-muted-foreground'
                    }`}
            >
              {sentiment}
            </CardDescription>
          </div>
          <Button
            variant={isFollowed ? 'default' : 'secondary'}
            size={'sm'}
            className="w-1/3 gap-2 rounded-full"
            onClick={() => onFollow?.(isFollowed ?? false, '1')}
          >
            {isFollowed ? (
              <MinusCircle size={15} className="flex-none" />
            ) : (
              <PlusCircle size={15} className="flex-none" />
            )}
            <span>{isFollowed ? 'Unfollow' : 'Follow'}</span>
          </Button>
        </CardHeader>
        <CardContent className="px-3">
          <div className="flex justify-between items-center text-sm">
            {/* This is rather verbose, but it can be solved differently depending on
             * the stats we get provided, I assumed (foolishly) that stats are numeric
             * and that we expect either a positive or negative number for trends */}
            {stats
              ? Object.entries(stats).map(([stat, value], i) => (
                  <div key={i} className="p-1 flex gap-2 items-center">
                    {stat === 'sentiment' ? (
                      value > 0 ? (
                        <TrendingUp size="16" className="text-green-500" />
                      ) : (
                        <TrendingDown size="16" className="text-red-500" />
                      )
                    ) : stat === 'news' ? (
                      <Newspaper size="16" />
                    ) : stat === 'pulse' ? (
                      <RadioTower size="16" />
                    ) : (
                      ''
                    )}
                    <span>{stat === 'news' ? value : value + '%'}</span>
                  </div>
                ))
              : ''}
          </div>
          <div className="space-y-2 mt-3 flex flex-col items-start">
            {sources?.map((source) => (
              <Badge key={source.value} variant={'outline'}>
                {source.label} — {source.value}%
              </Badge>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
