import { LineChart, Newspaper, RadioTower, GitFork } from 'lucide-react';
import { Card } from './card';
import { HoverCard, HoverCardTrigger, HoverCardContent } from './hover-card';

export const StatsCard = ({
  name = 'Cultural Heritage',
  sentiment = 'Positive',
  sentimentColor = 'green-400',
  trendPercentage = '25%',
  articlesNumber = 352,
  voicesNumber = 330,
  projectsNumber = 5,
  cardColor = 'bg-red-500',
  projects = [
    'Belgrano Sur Metropolitan Passenger Railway Line Mo...',
    'Northwestern Road Development Corridor Project',
    'Buenos Aires - Mitre Passenger Railway Line Moderni...',
    'National Roads and Airport Infrastructure Project',
    'Road Sector Capacity Development Project',
  ],
}) => (
  <Card className="font-sans p-4 max-w-sm shadow-none border-none">
    <div className="flex">
      <div className={`w-2 mr-2 min-h-full rounded-full ${cardColor}`}></div>
      <div>
        <div className="font-semibold text-sm">{name}</div>
        <div className={`text-xs ${sentimentColor} mt-1`}>{sentiment}</div>
      </div>
    </div>
    <div className="flex w-full justify-around mt-6">
      <div className="flex flex-col items-center justify-center">
        <LineChart />
        <div className={`font-semibold ${sentimentColor} text-lg mt-2`}>
          {trendPercentage}
        </div>
        <div className="text-gray-400 text-xs">Trend</div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Newspaper />
        <div className="font-semibold text-blue-500 text-lg mt-2">
          {articlesNumber}
        </div>
        <div className="text-gray-400 text-xs">Articles</div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <RadioTower />
        <div className="font-semibold text-blue-500 text-lg mt-2">
          {voicesNumber}
        </div>
        <div className="text-gray-400 text-xs">Voices</div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <GitFork />
        <HoverCard openDelay={200}>
          <HoverCardTrigger asChild>
            <div className="text-center cursor-pointer">
              <div className="font-semibold text-blue-500 text-lg mt-2">
                {projectsNumber}
              </div>
              <div className="text-gray-400 text-xs">Projects</div>
            </div>
          </HoverCardTrigger>
          <HoverCardContent className="w-96 rounded-md border border-gray-200 ">
            <div className="font-semibold">Projects affected</div>
            <div className="flex flex-col space-y-2">
              {projects &&
                projects.map((project, i) => (
                  <div
                    key={i}
                    className="overflow-hidden whitespace-nowrap text-ellipsis"
                  >
                    <span className="font-semibold mr-1">{i + 1}.</span>
                    {project}
                  </div>
                ))}
            </div>
          </HoverCardContent>
        </HoverCard>
      </div>
    </div>
  </Card>
);

export default StatsCard;
