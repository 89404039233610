import { CaretSortIcon } from '@radix-ui/react-icons';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@radix-ui/react-popover';
import { CheckIcon } from 'lucide-react';
import React from 'react';
import { cn } from '../../utils';
import { Button } from './button';
import {
  CommandInput,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Command,
} from './command';

const exampleData = [
  {
    value: 'next.js',
    label: 'Next.js',
  },
  {
    value: 'sveltekit',
    label: 'SvelteKit',
  },
  {
    value: 'nuxt.js',
    label: 'Nuxt.js',
  },
  {
    value: 'remix',
    label: 'Remix',
  },
  {
    value: 'astro',
    label: 'Astro',
  },
];

export const ComboboxSimple = ({
  placeholder = 'Select',
  value,
  setValue,
  data = exampleData,
}: {
  placeholder?: string;
  value: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  data?: { value: string; label: string }[];
}) => {
  const [open, setOpen] = React.useState(false);
  console.log('FIND?:', data.find((item) => item.value === value)?.label);
  console.log('data?:', value, data);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="max-w-xs w-full justify-between overflow-hidden rounded-full h-[32px] shadow-none"
        >
          <div className="max-w-[90%] overflow-hidden whitespace-nowrap text-xs text-ellipsis">
            {value !== undefined
              ? // for some reason all values in combobox become lowercase TODO FIXME
                data.find((item) => item.value.toLowerCase() === value)
                  ?.label ?? placeholder
              : placeholder}
          </div>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 z-10 border">
        <Command>
          <CommandInput placeholder={placeholder} className="h-9" />
          <CommandEmpty>No results found</CommandEmpty>
          <CommandGroup>
            {data.map((item) => (
              <CommandItem
                key={item.value}
                value={item.value}
                onSelect={(currentValue) => {
                  setValue(currentValue === value ? '' : currentValue);
                  setOpen(false);
                }}
              >
                {item.label}
                <CheckIcon
                  className={cn(
                    'ml-auto h-4 w-4',
                    value === item.value ? 'opacity-100' : 'opacity-0'
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
